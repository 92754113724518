<template>
    <div class="calendar_warp">
      <div class="calendar_content">
        <div class="calendar_left"></div>
        <div class="calendar_right">
          <FullCalendar ref="myCalendar" :options="calendarOptions"/>
        </div>
      </div>
      <a-modal
        title="日程添加"
        :visible="visible"
        dialogClass="addCalen"
        @ok="handleOk"
        @cancel="handleCancel"
        okText="添加"
      >
        <template slot="footer">
          <a-button key="back" @click="handleCancel">关闭</a-button>
          <a-button class="okBtn" key="confirm" @click="handleOk">添加</a-button>
        </template>
        <a-form>
          <a-form-item 
            label="标题" 
            :label-col="labelCol"
            :wrapper-col="wrapperCol">
            <a-input v-model="addEvent.title" placeholder="请输入标题"></a-input>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
</template>
 
<script>
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import listPlugin from '@fullcalendar/list'
  import moment from 'moment'

  export default {
    name: "index",
    components: {
        FullCalendar,
      
    },
    data(){
        return {
            addEvent: {},
            visible: false,
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 }
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 }
            },
            selectTimeStamp: [],
            calendarOptions: {
                // 引入的插件，比如fullcalendar/daygrid，fullcalendar/timegrid引入后才可显示月，周，日
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
                initialView: 'dayGridMonth', // 默认为那个视图（月：dayGridMonth，周：timeGridWeek，日：timeGridDay）
                events: [
                    // {
                    //     title : '黄娇变电站3020开关综合检修',
                    //     start : '2021-01-18 03:30',
                    //     end : '2021-01-18 04:30',
                    //     color:'#797979',
                    //     editable: true
                    // },
                    // {
                    //     title : '黄娇变电站3020开关综合检修',
                    //     start : '2021-01-18 04:30',
                    //     end : '2021-01-18 05:30',
                    //     color:'#797979',
                    //     editable: true
                    // },
                    // {
                    //     title : '黄娇变电站3020开关综合检修',
                    //     start : '2021-01-18 02:30',
                    //     end : '2021-01-18 03:30',
                    //     color:'#797979',
                    //     editable: true
                    // },//可以拖动但不能缩放，但在周、日视图中是可以进行缩放的
                    // {
                    //     title : '黄娇变电站3020开关综合检修',
                    //     start : '2021-01-19 00:30',
                    //     end : '2021-01-19 04:30',
                    //     color:'#5580EE',
                    //     editable: true
                    // }, //可以拖动、缩放
                    // {
                    //     title : '准备公司资料',
                    //     allDay: true,
                    //     start : '2021-01-21 00:00',
                    //     end : '2021-01-22 00:00',
                    //     color: '#EDB378',
                    //     editable: true,
                    //     // overlap: true,
                    //     // display: 'background',
                    // },
                    // {
                    //     title : '准备公司资料',
                    //     start: '2021-01-23 04:00',
                    //     end: '2021-01-23 05:00',
                    //     overlap: false,
                    //     // display: 'background',
                    //     color: '#797979'
                    // },//背景色 (添加相同时间的背景色时颜色会重叠) 一点要初始化日期时间 initialDate: '2020-07-10',
                ],
                firstDay: 1, // 设置一周中显示的第一天是哪天，周日是0，周一是1，类推
                locale: 'zh-cn', // 切换语言，当前为中文
                eventColor: '#3BB2E3', // 全部日历日程背景色
                themeSystem: 'bootstrap', // 主题色(本地测试未能生效)
                // initialDate: moment().format('YYYY-MM-DD'), // 自定义设置背景颜色时一定要初始化日期时间
                timeGridEventMinHeight: '20', // 设置事件的最小高度
                aspectRatio: 1.65, // 设置日历单元格宽度与高度的比例。
                // displayEventTime: false, // 是否显示时间
                allDaySlot: false, // 周，日视图时，all-day 不显示
                eventLimit: true, // 设置月日程，与all-day slot的最大显示数量，超过的通过弹窗显示
                headerToolbar: { // 日历头部按钮位置
                    left: 'today',
                    center: 'prev,title,next',
                    right: 'timeGridDay,timeGridWeek,dayGridMonth'
                },
                buttonText: {
                    today: '今天',
                    month: '月',
                    week: '周',
                    day: '日'
                },
                slotLabelFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: false,
                    hour12: false // 设置时间为24小时
                },
                eventLimitNum: { // 事件显示数量限制(本地测试未能生效)
                    dayGrid: {
                        eventLimit: 3
                    },
                    timeGrid: {
                        eventLimit: 2 // adjust to 6 only for timeGridWeek/timeGridDay
                    }
                },
                // 事件
                eventClick: this.handleEventClick,
                select: this.handleDateSelect, // 选中日历格事件
                selectable: true, // 是否可以选中日历格
                slotEventOverlap: false,  // 相同时间段的多个日程视觉上是否允许重叠，默认true允许
            }, 
            workingTicketVisible: false //工作表票详情页面

        }
    },
    mounted(){
    },
    methods: {
      handleOk() {
        console.log('addEvent', this.addEvent)
        this.calendarOptions.events.push(this.addEvent)
        console.log(this.calendarOptions.events)

        this.handleCancel()
      },
      handleCancel() {
        this.visible = false
        this.addEvent = {}
      },
      /**
       * 点击日历日程事件
       *
       * info: 事件信息
       * event是日程（事件）对象
       * jsEvent是个javascript事件
       * view是当前视图对象。
       */
      handleEventClick(info){
          console.log(info)
          
      },
      // 选中日历格快速新增
      handleDateSelect (selectInfo) {
        console.log('selectInfo', selectInfo)
        let tody = moment(new Date).format('YYYY-MM-DD')
        let starDate = moment(selectInfo.start).format('YYYY-MM-DD')
        let fley = moment(tody).isSameOrBefore(starDate)
        console.log('fley: ', fley);
        if(fley) {
          this.visible = true
          this.addEvent.allDay = selectInfo.allDay
          this.addEvent.start = moment(selectInfo.startStr).format('YYYY-MM-DD HH:mm')
          this.addEvent.end = moment(selectInfo.endStr).format('YYYY-MM-DD HH:mm')
          this.addEvent.color = '#BC0039'
        } else {
          this.$message.warn( '过去时间不能进行新增!')
        }
        // console.log(selectInfo.jsEvent.timeStamp)
        // 当点击两次同日历格时判断（要第一次点击选中之后在点击才会生效）
        // if ((moment(selectInfo.endStr).valueOf() - moment(selectInfo.startStr).valueOf() === 86400000 &&
        //   moment(selectInfo.startStr).valueOf() >= moment(moment().format('YYYY-MM-DD')).valueOf() &&
        //   selectInfo.view.type === 'dayGridMonth') ||
        //   ((selectInfo.view.type === 'timeGridWeek' || selectInfo.view.type === 'timeGridDay') &&
        //     moment(selectInfo.startStr).valueOf() >= moment(moment().format('YYYY-MM-DD')).valueOf() &&
        //     (selectInfo.allDay ? (moment(selectInfo.endStr).valueOf() - moment(selectInfo.startStr).valueOf() === 86400000)
        //       : (moment(selectInfo.end).valueOf() - moment(selectInfo.start).valueOf() > 1800000))
        //   )) {
        //   let temporaryTime = {}
        //   temporaryTime = {
        //     timeStamp: selectInfo.jsEvent.timeStamp,
        //     timeString: moment(selectInfo.startStr).format('YYYY-MM-DD HH:mm')
        //   }
          
        //   console.log('temporaryTime: ', temporaryTime);
        //   // 第一次点击时获取选中
        //   if (this.selectTimeStamp.length === 0) {
        //     let star = moment(selectInfo.startStr)
        //     console.log('star: ', star);
        //     this.visible = true
        //     this.addEvent.allDay = selectInfo.allDay
        //     this.addEvent.start = moment(selectInfo.startStr).format('YYYY-MM-DD HH:mm')
        //     this.addEvent.end = moment(selectInfo.endStrend).format('YYYY-MM-DD HH:mm')
        //     this.addEvent.color = '#BC0039'
        //     console.log('selectInfo', selectInfo)
        //     this.selectTimeStamp.push({
        //       timeStamp: selectInfo.jsEvent.timeStamp,
        //       timeString: moment(selectInfo.startStr).format('YYYY-MM-DD HH:mm')
        //     })
        //     console.log('selectTimeStamp1', this.selectTimeStamp)
        //   } else {
        //     // 第二次点击时判断是否和第一次一样，不一样就删除第一次的，添加第二次的
        //     this.selectTimeStamp.forEach(item => {
        //       console.log('item: ', item)
  
        //       if (item.timeString === temporaryTime.timeString) {
        //         console.log('ccc')
        //         this.visible = true
        //         this.addEvent.allDay = selectInfo.allDay
        //         this.addEvent.start = moment(selectInfo.startStr).format('YYYY-MM-DD HH:mm')
        //         this.addEvent.end = moment(selectInfo.endStr).format('YYYY-MM-DD HH:mm')
        //         this.addEvent.color = '#BC0039'
        //         //内容
        //       } else {
        //         this.selectTimeStamp.splice(this.selectTimeStamp.findIndex(event => event.timeString === item.timeString), 1)
        //         this.selectTimeStamp.push({
        //           timeStamp: selectInfo.jsEvent.timeStamp,
        //           timeString: moment(selectInfo.start).format('YYYY-MM-DD')
        //         })
        //         this.visible = true
        //         this.addEvent.allDay = selectInfo.allDay
        //         this.addEvent.start = moment(selectInfo.startStr).format('YYYY-MM-DD HH:mm')
        //         this.addEvent.end = moment(selectInfo.endStr).format('YYYY-MM-DD HH:mm')
        //         this.addEvent.color = '#BC0039'
        //         console.log('selectTimeStamp2', this.selectTimeStamp)
        //       }
        //     })
        //   }
        // }
        // 判断过去时间不能新增
        // if ((moment(selectInfo.endStr).valueOf() - moment(selectInfo.startStr).valueOf() > 86400000 &&
        //   moment(selectInfo.startStr).valueOf() < moment(moment().format('YYYY-MM-DD')).valueOf() &&
        //   selectInfo.view.type === 'dayGridMonth') ||
        //   ((selectInfo.view.type === 'timeGridWeek' || selectInfo.view.type === 'timeGridDay') &&
        //     moment(selectInfo.startStr).valueOf() < moment(moment().format('YYYY-MM-DD')).valueOf() &&
        //     (selectInfo.allDay ? (moment(selectInfo.endStr).valueOf() - moment(selectInfo.startStr).valueOf() > 86400000)
        //       : (moment(selectInfo.end).valueOf() - moment(selectInfo.start).valueOf() > 1800000))
        //   )) {
        //     this.$message.warn( '过去时间不能进行新增!')
        // } else{
        //   this.visible = true
        //   this.addEvent.allDay = selectInfo.allDay
        //   this.addEvent.start = moment(selectInfo.startStr).format('YYYY-MM-DD HH:mm')
        //   this.addEvent.end = moment(selectInfo.endStr).format('YYYY-MM-DD HH:mm')
        //   this.addEvent.color = '#BC0039'
        // }
      },
        
    }
  }
</script>
 
<style lang="less" scoped>
.calendar_warp{
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
  background: url('~@/assets/img/home_bg.png') no-repeat top center / cover;
  .calendar_content{
    width: 1500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .calendar_left{
    width: 440px;
    background: #fff;
    padding: 10px;
  }
  .calendar_right{
    width: 1050px;
    background: #fff;
    padding: 10px;
    /deep/.fc{
      .fc-header-toolbar{
        .fc-toolbar-chunk{
          .fc-today-button{
            width: 78px;
            height: 39px; 
            border-radius: 20px;
            background-color: rgba(255, 255, 255, 100);
            font-size: 18px;
            text-align: center;
            color: rgba(16, 16, 16, 100);
            border: 1px solid rgba(231, 237, 254, 100);
          }
          .fc-button-primary:focus {
            box-shadow: 0 0 0 0 rgba(76, 91, 106, 0.5);
          }
          .fc-prev-button{
            background: #fff;
            margin-right: 80px;
            color: rgba(16, 16, 16, 100);
            font-size: 16px;
            width: 50px;
            height: 36px;
            border: none;
            line-height: 36px;
            padding: 0px;
            margin-bottom: 5px;
          }
          .fc-next-button{
            background: #fff;
            margin-left: 80px;
            color: rgba(16, 16, 16, 100);
            font-size: 16px;
            width: 50px;
            height: 36px;
            border: none;
            line-height: 30px;
            padding: 0px;
            margin-bottom: 5px;
          }
          .fc-toolbar-title{
            display: inline-block;
            font-size: 20px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            color: rgba(16, 16, 16, 100);
            margin: 0px;
          }
          .fc-button-group{
            .fc-button-primary{
              width: 70px;
              height: 40px;
              color: #101010;
              font-size: 18px;
              background: #fff;
              padding: 0px;
              border: 1px solid rgba(231, 237, 254, 100);
            }
            .fc-button-active{
              color: #BC0039;
            }
          }
        }
      }
      .fc-view-harness{
        .fc-scrollgrid-section{
          .fc-col-header{
            .fc-scrollgrid-sync-inner{
              background: #BC0039;
              height: 35px;
              line-height: 30px;
              .fc-col-header-cell-cushion{
                color: #fff;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
          .fc-daygrid-body{
            .fc-scrollgrid-sync-table{
              .fc-daygrid-day{
                .fc-daygrid-day-top{
                  .fc-daygrid-day-number{
                    color: #101010;
                  }
                }
                .fc-daygrid-day-events{
                  .fc-daygrid-event{
                    color: #101010;
                  }
                }
                
              }
              .fc-day-today{
                background: #FFECEC;
                .fc-daygrid-day-frame{
                  border: 1px solid rgba(188, 0, 57, 100);
                  .fc-daygrid-day-top{
                    justify-content: space-around;
                    .fc-daygrid-day-number{
                      color: #BC0039;
                      font-weight: 600;
                    }
                  }
                  .fc-daygrid-day-events{
                    .fc-daygrid-event{
                      color:#BC0039;
                    }
                  }
                }
              }
            }
          }
          .fc-scroller-harness{
            .fc-scroller-liquid-absolute{
              .fc-timegrid-cols{
                .fc-day-today{
                  background: #FFECEC;
                }
              }
            }
          }
        }
      }
    }
  }
}
.okBtn{
  color: #fff;
  background: #BC0039;
}
body /deep/ .addCalen{
  /deep/ .ant-modal-content{
    .ant-modal-footer{
      .ant-btn-primary{
        background: #BC0039;
      }
    }
  }
}
</style>